import { navigate } from "gatsby";
import moment from "moment";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Img2 from "../assets/images/logo.png";

const KpPage = () => {
  const [reviewFormData, setReviewFormData] = useState({
    Name: "",
    MobileNo: "",
  });

  const [isSave, setIsSave] = useState(false);
  const [dataError, setDataError] = useState();

  const onFinish = () => {
    let data = {
      Name: reviewFormData.Name,
      MobileNo: reviewFormData.MobileNo,
    };

    let tempData = {
      Name: data.Name,
      Mobile_No: data.MobileNo,
      CreateDTTM: moment().format("YYYY-MM-DD HH:mm:ss"),
    };
    console.log(tempData, "sad");
    if (tempData.Name !== "" && tempData.Mobile_No !== "") {
      fetch(
        "https://script.google.com/macros/s/AKfycbyzOrGbu4grpyrRZSl3EX0kC_U07Mv19xzSv7rCqibiUMQINVRYWZIoJO69FjmkzDi1qw/exec?action=menuGuestData",
        {
          method: "POST",
          body: JSON.stringify(tempData),
          mode: "cors",
        }
      )
        .then((response) => {
          console.log(response, "response");
          navigate("/menu");
        })
        // .then((response) => {
        //   // console.log(response)
        //   // if (response.status === 200) {
        //     navigate(`/menu`);
        //   // }
        // })
        .catch((err) => {
          console.log("err", err);
        });
      setReviewFormData({
        Name: "",
        MobileNo: "",
      });
      setIsSave(!isSave);
    } else {
      if (tempData.Name === "" || tempData.Mobile_No === "") {
        setDataError("Please fill the fields that are marked in red *");
      }
    }
  };
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com"></link>
        <link rel="preconnect" href="https://fonts.googleapis.com"></link>
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin=""
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap"
          rel="stylesheet"
        ></link>
        <meta
          name="theme-color"
          content="#144a95"
          media="(prefers-color-scheme: light)"
        />
        <meta
          name="theme-color"
          content="#144a95"
          media="(prefers-color-scheme: dark)"
        />
        <meta name="theme-color" content="#144a95" />
      </Helmet>
      <div className="max-w-lg mx-auto bg-secondary-lightblue-300 h-full font-Nunito">
        <div className="py-8 z-10">
          <div className="flex justify-center pb-0">
            <img src={Img2} className="w-72 h-36" alt="title-icon" />
          </div>
        </div>
        <div className="font-Nunito text-secondary-lightblue-200 text-center font-extrabold mb-8">
          <div className="text-4xl">Welcome to</div>
          <div className="text-3xl">Ametrine 24, Andheri</div>
        </div>
        <div className="px-8 py-16 rounded-lg text-secondary-lightblue-500">
          <form>
            {dataError && <div className="md:px-14 px-6 pb-4">{dataError}</div>}
            <div className="py-2">
              <label className="text-xl pr-3">
                Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                autoComplete="false"
                className=" form-input text-base mt-1 p-2.5 bg-transparent border-2 border-secondary-lightblue-500 sm:w-full  w-full focus:outline-none rounded "
                value={reviewFormData?.Name}
                onChange={(e) =>
                  setReviewFormData({ ...reviewFormData, Name: e.target.value })
                }
                placeholder="Yours Name"
                required
              />
            </div>

            <div className="py-2">
              <label className="text-xl pr-3">
                Mobile No <span className="text-red-500">*</span>
              </label>
              <input
                pattern="\d*"
                maxLength="10"
                type="text"
                className=" form-input text-base mt-1 p-2.5 bg-transparent border-2 border-secondary-lightblue-500 sm:w-full  w-full focus:outline-none rounded "
                value={reviewFormData?.MobileNo}
                onChange={(e) =>
                  setReviewFormData({
                    ...reviewFormData,
                    MobileNo: e.target.value,
                  })
                }
                placeholder="Your's Mobile Number"
                required
              />
            </div>

            <div className="py-8 flex flex-col justify-between">
              <button
                type="submit"
                onClick={() => {
                  onFinish();
                }}
                className="shadow-bottom transition duration-500 bg-secondary-lightblue-500 text-secondary-lightblue-300 p-4 font-bold w-full rounded mb-4"
              >
                Submit
              </button>
            </div>
            <div className="flex justify-end items-end">
              <button
                onClick={() => {
                  navigate("/menu");
                }}
                className="shadow-bottom transition duration-500 text-secondary-lightblue-500 pt-4 font-bold border-b border-secondary-darkblue-500 max-w-max ml-auto mb-4 text-sm"
              >
                Skip
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default KpPage;
